import React, { useEffect } from 'react';
import './App.scss';
import appInsights from './AppInsights';
import { Container, Row, Col } from 'react-bootstrap';

const App: React.FC = () => {
  useEffect(() => {
    appInsights.trackPageView(); // Track page view when the component mounts
  }, []);

  useEffect(() => {
    const videoElement = document.querySelector('video');
    if (videoElement) {
      const playVideo = () => {
        videoElement.play().catch(() => {
          alert('Failed to play video');
        });
      };
      videoElement.addEventListener('canplay', playVideo);

      return () => {
        videoElement.removeEventListener('canplay', playVideo);
      };
    }
  }, []);

  return (
    <div className="App">
      <video autoPlay loop muted playsInline className="video-background" poster='background.jpg'>
        <source src="https://bndgpswasa.blob.core.windows.net/public-bakkerendegreef/Background.webm" type="video/webm" />
        <source src="https://bndgpswasa.blob.core.windows.net/public-bakkerendegreef/background.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <Container className="h-100 d-flex align-items-center justify-content-center">
        <Row>
          <Col className="text-center">
      <div className="logo-container">
        <img src="logo.png" className="logo" alt="logo" />
      </div>
      </Col>
        </Row>
      </Container>
    </div>
  );
}

export default App;
